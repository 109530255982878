const state = {
    // ------------ development -------------
    // httpURL: 'http://127.0.0.1:3500',
    // ------------ testing ------------
    httpURL: 'https://test.hiperlector.com/api',
    // ------------ production ------------
    // httpURL: 'https://api.hiperlector.com',
    sessionToken: null,
    userId: '',
    userName: 'Unknown user',
    userAvatar: '',
    googleUser: false,
    appleUser: false,
    modoOscuro: true,
    appError: null
}

const getters = {
    httpURL: state => {
        return state.httpURL;
    },
    sessionToken: state => {
        return state.sessionToken;
    },
    userId: state => {
        return state.userId;
    },
    userName: state => {
        return state.userName;
    },
    userAvatar: state => {
        return state.userAvatar;
    },
    googleUser: state => {
        return state.googleUser;
    },
    appleUser: state => {
        return state.appleUser;
    },
    modoOscuro: state => {
        return state.modoOscuro;
    },
    appError: state => {
        return state.appError;
    }
}

const actions = {
    setSessionToken(context, payload) {
        context.commit('setSessionTokenHandler', payload);
    },
    setUserId(context, payload) {
        context.commit('setUserIdHandler', payload);
    },
    setUserName(context, payload) {
        context.commit('setUserNameHandler', payload);
    },
    setUserAvatar(context, payload) {
        context.commit('setUserAvatarHandler', payload);
    },
    setGoogleUser(context, payload) {
        context.commit('setGoogleUserHandler', payload);
    },
    setAppleUser(context, payload) {
        context.commit('setAppleUserHandler', payload);
    },
    setModoOscuro(context, payload) {
        context.commit('setModoOscuroHandler', payload);
    },
    setAppError(context, payload) {
        context.commit('setAppErrorHandler', payload);
    }
}

// mutations
const mutations = {
    setSessionTokenHandler(state, token) {
        state.sessionToken = token;
    },
    setUserIdHandler(state, name) {
        state.userId = name;
    },
    setUserNameHandler(state, name) {
        state.userName = name;
    },
    setUserAvatarHandler(state, avatar) {
        state.userAvatar = avatar;
    },
    setGoogleUserHandler(state, googleUser) {
        state.googleUser = googleUser;
    },
    setAppleUserHandler(state, appleUser) {
        state.appleUser = appleUser;
    },
    setModoOscuroHandler(state, name) {
        state.modoOscuro = name;
    },
    setAppErrorHandler(state, mensaje) {
        state.appError = mensaje;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}