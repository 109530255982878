import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import router from './router'
import { store } from './store';

import GoogleAuth from '@/config/google_oAuth.js'
const gauthOption = {
    clientId: '773148635192-6eneiabjgin0fknfhb5pdc26ed85hk3c.apps.googleusercontent.com',
    scope: 'profile email',
    prompt: 'select_account'
}

import ValidateResponse from './plugins/validateResponse';
import notificaciones from './plugins/notificaciones';

Vue.config.productionTip = false
Vue.use(GoogleAuth, gauthOption)
Vue.use(ValidateResponse, router)
Vue.use(notificaciones, store)

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')