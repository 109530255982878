export default {
    
    install(Vue, store) {
        Vue.prototype.$appError = (mensaje) => {
            const data = {mensaje, tipo:'error'}
            store.dispatch("setAppError", data);
        }
        Vue.prototype.$appWarning = (mensaje) => {
            const data = {mensaje, tipo:'warning'}
            store.dispatch("setAppError", data);
        }
    }
}