export default {
    
    install(Vue, router) {
        Vue.prototype.$validateResponse = (response) => {
            if (!response.ok) {
                if (response.noAuth) {
                    localStorage.removeItem("hiperToken");
                    router.push('/login');
                    throw new Error(response.mensaje);
                }
                if (!response.operational) {
                    throw new Error(response.mensaje);
                }
            }
        }
    }
}