import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const loginView = () =>
    import ('@/components/login/login.view')

// Containers
const MainTemplate = () =>
    import ('@/template/mainTemplate.view')
const MainView = () =>
    import ('@/components/dashboard/dashboard.view')
const ModuloView = () =>
    import ('@/components/modulos/modulo.view')
const EjercicioView = () =>
    import ('@/components/ejercicios/ejercicio.view')

const ActivarCuentaView = () =>
    import ('@/components/login/activarCuenta.view')
const RestablecerPassView = () =>
    import ('@/components/login/restablecerPass.view')
const TerminosCondicionesView = () =>
    import ('@/views/terminosCondiciones')
const AvisoPrivacidadView = () =>
    import ('@/views/avisoPrivacidad')
const Page404 = () =>
    import ('@/views/page404')


export default new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [{
            path: '/',
            redirect: '/dashboard',
            name: 'Home',
            component: MainTemplate,
            props: { appName: 'Campus' },
            beforeEnter: (to, from, next) => {
                const tokenLocal = localStorage.getItem("hiperToken");
                if (tokenLocal) next();
                else next('/login')
            },
            children: [
                { path: 'dashboard', name: 'Inicio', component: MainView },
                { path: 'modulo/:moduloRoute', name: 'moduloView', component: ModuloView },
                { path: 'ejercicio/:moduloRoute/:clave', name: 'ejercicioView', component: EjercicioView },
            ]
        },
        { path: '/login', name: 'Login', component: loginView },
        { path: '/activacion/:tokenActivacion', name: 'Activacion', component: ActivarCuentaView },
        { path: '/restablecer-password/:token', name: 'Reestablecer password', component: RestablecerPassView },
        { path: '/terminos-y-condiciones', name: 'Terminos y condiciones', component: TerminosCondicionesView },
        { path: '/aviso-privacidad', name: 'Aviso de privacidad', component: AvisoPrivacidadView },
        {
            //Cualquier ruta que no exista, se redireccionará al main
            path: '*',
            // redirect: '/main',
            name: 'Not found',
            component: Page404
        }
    ]
})